import React, { Fragment, useEffect } from 'react';
import "aos/dist/aos.css";
import Aos from 'aos'


function About(props) {
    useEffect(()=> {
        Aos.init({duration: 2000});
    }, [])
    
    return (
        <Fragment>
            <section id="about" className="about">
                <div className="container boite py-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="titre text-center">{props.titre}</h1>
                        </div>
                    </div>
                    <div className="row  p-4">
                        <div className="col-lg-8 col-md-12 col-sm-12" data-aos="fade-up">
                        <p>Etudiante en dernière d'informatique de gestion à l'EPFC et formée en full Stack développement web à MolenGeek. 
                        Pendant cette formation, j'ai acquis une expertise approfondie dans un large éventail de technologies et de langages, 
                        notamment HTML, CSS, React, Vue, PHP, MySQL et Laravel.</p>      
                        

                        <p>Au cours de cette période d'apprentissage enrichissante, j'ai eu l'occasion de concevoir et de développer des applications web innovantes, interactives et intuitives. 
                        Mon objectif était de maîtriser chaque aspect du développement web, des fondements du front-end à la mise en place d'une base de données robuste en back-end. </p>
                        
                        <p>À travers les différents projets présentés dans ce portfolio, vous découvrirez comment j'ai pu exploiter mes compétences pour créer des sites web réactifs et esthétiquement agréables en utilisant HTML et CSS. De même, vous pourrez explorer des applications plus dynamiques et interactives, développées à l'aide des frameworks React et Vue.
                        </p>
                        </div>

                        <div className="col-lg-4 col-md-12 col-sm-12 d-flex flex-column" data-aos="fade-up">
                            <img src="../img/avatar.png" alt="dev" className="w-75 mx-auto"/>
                            <a className="btn-grad  mt-5 bouton" href="https://www.jamilahmn.be/CV-Jamila-H.pdf" rel="noreferrer" target="_blank">MON CV</a>
                        </div>
                    </div>
                </div>

            </section>
        </Fragment>
    );
}

export default About;